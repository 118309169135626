import React, { useState } from 'react';
import { hostname } from '../../utils/constants';
import SiteLayout from '../../components/layout/SiteLayout';
import HomepageIndex from '../homepage/homepage-index';
import Testimonials from '../homepage/testimonials';
import AndDigitalTestimonial from '../homepage/and-digital-testimonial';
import Mobile from '../homepage/mobile';
import HomepageFeatures from '../../components/homepage-features';
import BottomText from '../homepage/bottom-text';
import loadable from '@loadable/component';
const RoContact = loadable(() => import('../../templates/homepage/ro-contact'));

const Homepage = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  let ldJsonHome = "Pagina Principale";

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": ldJsonHome
        }
      }
    ]
  };

  return (
    <SiteLayout relatedPages={data?.mdx?.fields?.relatedPages} lang={data?.mdx?.fields?.lang} canonical={data?.mdx?.fields?.canonical} useCanonical={true} seo={{metaTitle: data?.mdx?.frontmatter?.metaTitle, metaDescription: data?.mdx?.frontmatter?.metaDescription, metaImage: data?.mdx?.frontmatter?.metaImage?.publicURL, metaCover: data?.mdx?.frontmatter?.metaCover?.publicURL, twitterCard: data?.mdx?.frontmatter?.twitterCard, ldJson: data?.mdx?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: data?.mdx?.frontmatter?.metaKeywords}}>
      <HomepageIndex data={data?.mdx} language={data?.mdx?.fields?.lang} />
      <Testimonials testimonials={data?.mdx?.exports?.testimonials} language={data?.mdx?.fields?.lang}/>
      <AndDigitalTestimonial data={data?.mdx} language={data?.mdx?.fields?.lang} />
      <Mobile data={data?.mdx} />
      <HomepageFeatures diveInText={data?.mdx?.frontmatter?.diveIn} tryItTexts={data?.mdx?.frontmatter?.tryItTexts} language={data?.mdx?.fields?.lang} />
      <BottomText data={data?.mdx} />

      <RoContact/>
    </SiteLayout>
  );
};

export default Homepage;
